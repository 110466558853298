import RadioButton from "../../../../components/RadioButton";
import StepContainer from "../../../../components/StepContainer";
import StepRoutes from "../../../../constants/Routes";
import { useStore } from "../../../../store/store";
import { AseFieldset, AseList } from "../../../../utils/global.styles";

export default function OccupationStep() {
  const t = useStore.useTranslations();
  const setPayload = useStore.useSetPayload();
  const payload = useStore.usePayload();
  const setRouteStack = useStore.useSetRouteStack();
  const useResetPayload = useStore.useResetPayload();

  return (
    <StepContainer
      stepTitle={t?.occupationStep.title}
      progress={0}
      buttonText={t?.occupationStep.buttonLabel}
      subtitle="Velkommen til familiefagforeningen!"
      helpText={t?.occupationStep.helpText}
      onNext={() => {
        if (payload.occupation === "Employee" || payload.occupation === "Unemployed") {
          setRouteStack(StepRoutes.MembershipStep, payload);
        } else if (payload.occupation === "SelfEmployed") {
          setPayload({ cause: "Selvstændig som forsøger at tilmelde sig som partner til familie fagforening." });
          setRouteStack(StepRoutes.NeedToContactYouStep, payload);
        } else if (payload.occupation === "Student") {
          setPayload({ cause: "Student som forsøger at tilmelde sig som partner til familie fagforening." });
          setRouteStack(StepRoutes.NeedToContactYouStep, payload);
        }
      }}
    >
      <AseFieldset>
        <AseList>
          <RadioButton
            onChange={() => {
              setPayload({ occupation: "Employee" });
            }}
            id="Employee"
            checked={payload.occupation === "Employee"}
          >
            {t?.occupationStep.employed}
          </RadioButton>

          <RadioButton
            onChange={() => {
              setPayload({ occupation: "Unemployed" });
            }}
            id="Unemployed"
            checked={payload.occupation === "Unemployed"}
          >
            {t?.occupationStep.unemployed}
          </RadioButton>

          <RadioButton
            onChange={() => {
              setPayload({ occupation: "SelfEmployed" });
            }}
            id="SelfEmployed"
            checked={payload.occupation === "SelfEmployed"}
          >
            {t?.occupationStep.selfEmployed}
          </RadioButton>
          <RadioButton
            onChange={() => {
              setPayload({ occupation: "Student" });
            }}
            id="Student"
            checked={payload.occupation === "Student"}
          >
            {t?.occupationStep.student}
          </RadioButton>
        </AseList>
      </AseFieldset>
    </StepContainer>
  );
}
