import React, { useEffect, useMemo } from "react";
import { IoArrowForward } from "react-icons/io5";
import StepContainer from "../../../../components/StepContainer";
import StepRoutes from "../../../../constants/Routes";
import { useStore } from "../../../../store/store";
import CheckCard from "../../../../components/CheckCard";
import TotalAmountBox from "../../../../components/TotalAmountBox";
import { PaymentTypeEnum } from "../../../../types/PaymentTypesEnum";
import Analytics from "../../../../utils/analytics";
import { formatNumber } from "../../../../utils/formatNumber";
import { allowedMarketingCookies } from "../../../../utils/cookiesAllowed";
import { setCookie } from "../../../../utils/cookieService";
import { SalaryInsurance, Subtitle } from "./ProductStep.styles";
import { ProductCardWrapper } from "./ProductStep.styles";
import ProductCard from "../../../../components/ProductCard";
import { ProductTypesEnum } from "../../../../types/ProductTypesEnum";
import { AseFieldset, AseList } from "../../../../utils/global.styles";
import { TrackingFlowTypeEnum } from "../../../../types/TrackingFlowTypeEnum";

export default function ProductsStep() {
  const t = useStore.useTranslations();
  const setPayload = useStore.useSetPayload();
  const payload = useStore.usePayload();
  const offerEnabled = useStore.useOfferEnabled();
  const setRouteStack = useStore.useSetRouteStack();

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const product = urlParams.get("produkt");
    const occupation = urlParams.get("type");

    if (product) {
      setPayload({
        wantsUnemploymentBenefits: false,
        wantsSalaryInsurance: false,
        wantsUnion: false,
        wantsHRJura: false,
      });
    }

    if (occupation?.toLowerCase() === "selfemployed") {
      if (payload.occupation === "SelfEmployed") {
        if (product === "akasse") {
          setPayload({
            wantsUnemploymentBenefits: true,
          });
        }

        if (product === "akasse|hrjura") {
          setPayload({
            wantsUnemploymentBenefits: true,
            wantsHRJura: true,
          });
        }

        if (product === "hrjura") {
          setPayload({
            wantsHRJura: true,
          });
        }

        if (product === "akasse|loensikring") {
          setPayload({
            wantsUnemploymentBenefits: true,
            wantsSalaryInsurance: true,
          });
        }
      }

      if (payload.occupation === "Employee") {
        if (product === "akasse|hrjura" || product === "akasse") {
          setPayload({
            wantsUnemploymentBenefits: true,
          });
        }

        if (product === "akasse|loensikring") {
          setPayload({
            wantsUnemploymentBenefits: true,
            wantsHRJura: false,
            wantsSalaryInsurance: true,
            wantsUnion: false,
          });
        }
      }

      if (payload.occupation === "Unemployed") {
        if (product === "akasse|hrjura" || product === "akasse" || product === "akasse|loensikring") {
          setPayload({
            wantsUnemploymentBenefits: true,
          });
        }
      }
    }

    if (occupation?.toLowerCase() === "employee") {
      if (payload.occupation === "Employee" || payload.occupation === "Unemployed") {
        if (product === "akasse") {
          setPayload({
            wantsUnemploymentBenefits: true,
          });
        }
        if (product === "fagforening") {
          setPayload({
            wantsUnion: true,
          });
        }
        if (product === "akasse|fagforening") {
          setPayload({
            wantsUnemploymentBenefits: true,
            wantsUnion: true,
          });
        }
      }

      if (payload.occupation === "SelfEmployed") {
        if (product === "akasse") {
          setPayload({
            wantsUnemploymentBenefits: true,
          });
        }

        if (product === "akasse|fagforening") {
          setPayload({
            wantsUnemploymentBenefits: true,
          });
        }
      }
    }
  }, []);

  const rows = useMemo(() => {
    const r = [];

    const UnemploymentInsurancePrice = payload.wantsUnemploymentBenefits ? Number(t?.productsStep.unemploymentInsurance.price) : 0;
    const UnionPrice = payload.wantsUnion && payload.occupation !== "SelfEmployed" ? Number(t?.productsStep.union.price) : 0;
    const FamilyUnionPrice = payload.wantsFamilyUnion && payload.occupation !== "SelfEmployed" ? Number(t?.productsStep.familyUnion.price) : 0;

    let totalAmount = UnemploymentInsurancePrice;

    if (payload.wantsUnion) {
      totalAmount = totalAmount + (offerEnabled && payload.wantsUnemploymentBenefits ? 0 : UnionPrice);
    }

    if (payload.wantsFamilyUnion) {
      totalAmount = totalAmount + FamilyUnionPrice;
    }

    if (payload.wantsUnemploymentBenefits) {
      r.push({
        label: t?.productsStep.unemploymentInsurance.title,
        value: `${formatNumber(UnemploymentInsurancePrice)} ${t?.general.krPerMonth}`,
      });
    }

    if (payload.wantsHRJura && payload.occupation === "SelfEmployed") {
      r.push({
        label: t?.summaryStep.hrJura.title,
        value: t?.summaryStep.hrJura.price,
      });
    }

    if (payload.wantsUnion && payload.occupation !== "SelfEmployed") {
      r.push({
        label: t?.productsStep.union.title,
        value: payload.wantsUnemploymentBenefits && offerEnabled ? t?.productsStep.offerRowText : `${formatNumber(UnionPrice)} ${t?.general.krPerMonth}`,
      });
    }

    if (payload.wantsFamilyUnion && payload.occupation !== "SelfEmployed") {
      r.push({
        label: `${t?.productsStep.familyUnion.title} (tilføjes som partner)`,
        value: `0 ${t?.general.krPerMonth}`,
      });
    }

    r.push({
      label: t?.general.totalAmount,
      value: `${formatNumber(totalAmount)} ${t?.general.krPerMonth}`,
      bold: true,
    });

    return r as { label: string; value: string; bold?: boolean }[];
  }, [payload, t]);

  useEffect(() => {
    if (payload.wantsUnemploymentBenefits && payload.wantsUnion && offerEnabled) {
      setPayload({ combinationDiscount: true });
    } else {
      setPayload({ combinationDiscount: false });
    }
  }, [payload.wantsUnemploymentBenefits, payload.wantsUnion]);

  return (
    <StepContainer
      stepTitle={t?.productsStep.title}
      progress={0.1}
      buttonText={t?.productsStep.buttonLabel}
      helpText={t?.productsStep.helpText}
      subtitle="Velkommen til familiefagforeningen!"
      onNext={() => {
        // Add tracking
        Analytics.getInstance().trackProducts(payload, offerEnabled, t, TrackingFlowTypeEnum.PARTNER);

        if (payload.wantsUnemploymentBenefits && (payload.wantsUnion || payload.wantsFamilyUnion)) {
          setPayload({
            paymentType: PaymentTypeEnum.BSPAYMENT,
          });
        } else {
          setPayload({
            paymentType: PaymentTypeEnum.MOBILE_PAY,
          });
        }

        if (payload.wantsFamilyUnion && !payload.wantsSalaryInsurance && !payload.wantsHRJura && !payload.wantsUnemploymentBenefits && !payload.wantsUnion) {
          setRouteStack(StepRoutes.NeedToContactYouStep, payload);
          return;
        }

        if (payload.occupation === "SelfEmployed") {
          if (payload.wantsUnemploymentBenefits) {
            setRouteStack(StepRoutes.SelfEmployedStep, payload);
          } else {
            setPayload({ cause: "Selvstændig og ønsker a-kasse" });
            setRouteStack(StepRoutes.NeedToContactYouStep, payload);
          }
        } else {
          if (payload.wantsUnemploymentBenefits || payload.wantsUnion || payload.wantsFamilyUnion) {
            setRouteStack(StepRoutes.MembershipStep, payload);
          } else {
            setPayload({ cause: "Ønsker kun lønsikring og ikke a-kasse og fagforening" });
            setRouteStack(StepRoutes.NeedToContactYouStep, payload);
          }
        }
      }}
      nextDisabled={!payload.wantsFamilyUnion && !payload.wantsUnion && !payload.wantsHRJura && !payload.wantsUnemploymentBenefits && !payload.wantsSalaryInsurance}
    >
      <AseFieldset>
        <AseList>
          <CheckCard
            id="UnemploymentInsurance"
            onChange={() =>
              setPayload({
                wantsUnemploymentBenefits: !payload.wantsUnemploymentBenefits,
              })
            }
            isChecked={payload.wantsUnemploymentBenefits}
            title={t?.productsStep.unemploymentInsurance.title}
            price={`${t?.productsStep.unemploymentInsurance.price} ${t?.general.krPerMonth}`}
            description={t?.productsStep.unemploymentInsurance.description}
            modalDescriptionHtml={t?.productsStep.unemploymentInsurance.information}
          />

          <CheckCard
            id="SalaryGuarantee"
            onChange={() =>
              setPayload({
                wantsSalaryInsurance: !payload.wantsSalaryInsurance,
              })
            }
            isChecked={payload.wantsSalaryInsurance}
            title={t?.productsStep.salaryGuarantee.title}
            price={t?.productsStep.callMe}
            description={t?.productsStep.salaryGuarantee.description}
            modalDescriptionHtml={t?.productsStep.salaryGuarantee.information}
          />

          {payload.wantsSalaryInsurance && (
            <SalaryInsurance>
              <IoArrowForward size={22} />
              <div>
                <b>Husk!</b> Tjener du mindre end 27.160 kr./md. før skat, kan du ikke tegne lønsikring, da du er dækket af dine dagpenge.
              </div>
            </SalaryInsurance>
          )}

          {payload.occupation !== "SelfEmployed" && (
            <>
              <Subtitle>Fagforening:</Subtitle>
              <ProductCardWrapper>
                <ProductCard
                  active={payload.wantsUnion}
                  onActive={() => {
                    setPayload({
                      wantsUnion: !payload.wantsUnion,
                      wantsFamilyUnion: false,
                    });
                  }}
                  type={ProductTypesEnum.Union}
                  lead="TILBUD: 6 mdr. GRATIS fagforening ved køb af a-kasse til dig selv"
                  name="Fagforening"
                  numberOfPeople="(1 voksen)"
                  description="- få juridisk hjælp i dit arbejdsliv."
                  price={t?.productsStep.union.price}
                  modalDescriptionHtml={t?.productsStep.union.information}
                ></ProductCard>
                <ProductCard
                  active={payload.wantsFamilyUnion}
                  onActive={() => {
                    setPayload({
                      wantsUnion: false,
                      wantsFamilyUnion: !payload.wantsFamilyUnion,
                    });
                  }}
                  type={ProductTypesEnum.FamilyUnion}
                  lead="NYHED: Et godt arbejdsliv til hele familien"
                  name="Familiefagforening"
                  numberOfPeople="(2 voksne + børn op til 21 år)"
                  description="- få juridisk hjælp til hele familiens arbejdsliv."
                  price={t?.productsStep.familyUnion.price}
                  modalDescriptionHtml={t?.productsStep.familyUnion.information}
                />
              </ProductCardWrapper>
            </>
          )}

          {payload.occupation === "SelfEmployed" && (
            <CheckCard
              id="HRJura"
              onChange={() =>
                setPayload({
                  wantsHRJura: !payload.wantsHRJura,
                })
              }
              isChecked={payload.wantsHRJura}
              title={t?.productsStep.hrjura.title}
              price={`fra ${t?.productsStep.hrjura.price} ${t?.general.krPerMonth}`}
              description={t?.productsStep.hrjura.description}
              modalDescriptionHtml={t?.productsStep.hrjura.information}
            />
          )}

          {(payload.wantsFamilyUnion || payload.wantsUnion || payload.wantsUnemploymentBenefits) && <TotalAmountBox hideHeader rows={rows} />}
        </AseList>
      </AseFieldset>
    </StepContainer>
  );
}
