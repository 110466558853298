import { useState } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import StepContainer from "../../../../components/StepContainer";
import TotalAmountBox from "../../../../components/TotalAmountBox";
import StepRoutes from "../../../../constants/Routes";
import { useStore } from "../../../../store/store";
import { fetchSignupDate } from "../../../../utils/api";
import Modal from "../../../../components/Modal";
import { ASESummaryHeader } from "../../../../utils/global.styles";
import { Row } from "./SummaryStep.styles";
import GiftCardPopup from "../../../../components/GiftCardPopup";

export default function SummaryStep() {
  const t = useStore.useTranslations();
  const payload = useStore.usePayload();
  const setRouteStack = useStore.useSetRouteStack();
  const setPayload = useStore.useSetPayload();
  const offerEnabled = useStore.useOfferEnabled();
  const [showErrorModal, setShowErrorModal] = useState(false);

  const getUnemploymentPrice = useMemo(() => {
    if (payload.wantsUnemploymentBenefits && payload.occupation === "SelfEmployed") {
      return t?.productsStep.unemploymentInsurance.price_selfemployed
    } else if(payload.wantsUnemploymentBenefits && payload.occupation !== "SelfEmployed") {
      return t?.productsStep.unemploymentInsurance.price
    } else {
      return 0
    }
  }, [payload, payload.occupation, t]);

  const rows = useMemo(() => {
    const r = [];

    const UnemploymentInsurancePrice = Number(getUnemploymentPrice);
    const UnionPrice = payload.wantsUnion ? Number(t?.summaryStep.union.price) : 0;
    //Price for familyUnion when there is no offer
    /* const FamilyUnionPrice = payload.wantsFamilyUnion && payload.occupation !== "SelfEmployed" ? Number(t?.productsStep.familyUnion.price) : 0; */
    const FamilyUnionPrice = payload.wantsUnemploymentBenefits ? 0 : Number(t?.productsStep.familyUnion.price);

    let totalAmount = UnemploymentInsurancePrice;

    if (payload.wantsUnion) {
      totalAmount = totalAmount + (offerEnabled && payload.wantsUnemploymentBenefits ? 0 : UnionPrice);
    }

    if (payload.wantsFamilyUnion) {
      totalAmount = totalAmount + FamilyUnionPrice;
    }

    if (payload.wantsUnemploymentBenefits) {
      r.push({
        label: t?.summaryStep.unemploymentInsurance.title,
        value: `${UnemploymentInsurancePrice} ${t?.general.krPerMonth}`,
      });
    }

    if (payload.wantsUnion) {
      r.push({
        label: t?.productsStep.union.title,
        value: payload.wantsUnemploymentBenefits && offerEnabled ? t?.productsStep.offerRowText : `${UnionPrice} ${t?.general.krPerMonth}`,
      });
    }

    if (payload.wantsFamilyUnion) {
      r.push({
        label: t?.productsStep.familyUnion.title,
        //Price for familyUnion when there is no offer
        /*value: `${FamilyUnionPrice} ${t?.general.krPerMonth}`,*/
        value: payload.wantsUnemploymentBenefits ? t?.productsStep.offerRowText : `${FamilyUnionPrice} ${t?.general.krPerMonth}`,
      });
    }

    if (payload.wantsSalaryInsurance) {
      r.push({
        label: t?.summaryStep.salaryInsurance.title,
        value: `${t?.summaryStep.salaryInsurance.price}`,
      });
    }

    if (payload.wantsBasic) {
      r.push({
        label: t?.summaryStep.basic.title,
        value: t?.summaryStep.basic.price,
      });
    }

    if (payload.wantsHRJura) {
      r.push({
        label: t?.summaryStep.hrJura.title,
        value: `${t?.summaryStep.hrJura.price}`,
      });
    }

    r.push({
      label: t?.general.totalAmount,
      value: `${totalAmount} ${t?.general.krPerMonth}`,
      bold: true,
    });

    return r as { label: string; value: string; bold?: boolean }[];
  }, [payload, t]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await fetchSignupDate(payload);
        setPayload({ signupDate: response.data.signupdate });
      } catch (err) {
        setShowErrorModal(true);
      }
    };

    if (payload) {
      fetch();
    }
  }, []);

  const infoText = useMemo(() => {
    const productStrings = [];

    if (payload.wantsSalaryInsurance) {
      productStrings.push("lønsikring");
    }

    if (payload.wantsBasic) {
      productStrings.push("Ase selvstændig");
    }

    if (payload.wantsHRJura) {
      productStrings.push("HR Jura");
    }

    const joinedProductString = productStrings.reduce((acc, cur, i) => {
      if (i + 1 === productStrings.length && productStrings.length > 1) {
        return acc + " og " + cur;
      } else {
        const seperator = acc ? ", " : "";
        return acc + seperator + cur;
      }
    }, "");

    return `Vi kontakter dig vedr. tilbud på ${joinedProductString}`;
  }, [payload]);

  return (
    <>
      <StepContainer
        stepTitle={t?.summaryStep.title}
        progress={0.9}
        buttonText={t?.summaryStep.buttonLabel}
        helpText={t?.summaryStep.helpText}
        onNext={() => {
          setRouteStack(StepRoutes.PaymentStep, payload);
        }}
      >
        <ASESummaryHeader>
          <h3>
            <strong>{t?.summaryStep.personalInformation}</strong>
          </h3>
        </ASESummaryHeader>
        <div>
          <Row>
            <span>{t?.informationAboutYouStep.cpr}</span>
            <span>{payload.socialSecurityNumber.slice(0, 6)}••••</span>
          </Row>
          <Row>
            <div>{t?.informationAboutYouStep.name}</div>
            <div>
              {payload.firstName} {payload.lastName}
            </div>
          </Row>
          <Row>
            <div>{t?.informationAboutYouStep.address}</div>
            <div>{`${payload.addressText}`}</div>
          </Row>
          <Row>
            <div>{t?.informationAboutYouStep.mail}</div>
            <div>{payload.mailAddress}</div>
          </Row>
          <Row>
            <div>{t?.informationAboutYouStep.phone}</div>
            <div>{payload.mobilePhone}</div>
          </Row>
        </div>

        {(payload.wantsSalaryInsurance || payload.wantsBasic || payload.wantsHRJura) && (
          <p style={{ paddingTop: 30, color: "var(--color-orange)" }}>
            <strong>{infoText}</strong>
          </p>
        )}

        <TotalAmountBox date={payload.signupDate} rows={rows} />
      </StepContainer>
      <Modal modalDescriptionHtml={t?.summaryStep.errorMessage ?? ""} open={showErrorModal} onClose={() => setShowErrorModal(false)} />
    </>
  );
}
