import { BiLoader, BiCheck, BiXCircle, BiUserPlus, BiPlus, BiCoin, BiDonateHeart, BiHeart } from "react-icons/bi";
import { AnimationWrapper, Badge, ButtonWrapper, Date, Description, Inner, StateWrapper, Title, Wrapper } from "./TrackTraceItem.styles";
import { useMemo, useState } from "react";
import { useStore } from "../../store/store";
import Button from "../Button";
import { TrackTraceItemModel } from "../../types/TrackTraceItemModel";
import { TrackTracerItemStateEnum } from "../../types/TrackTracerItemStateEnum";
import { TrackTracerItemActionsEnum } from "../../types/TrackTracerItemActionsEnum";
import dayjs from "dayjs";

export default function TrackTraceItem({ item }: { item: TrackTraceItemModel }) {
  const [loading, setLoading] = useState(false);
  const setUnionFlowVisible = useStore.useSetUnionFlowVisible();
  const setSalaryInsuranceVisible = useStore.useSetSalaryInsuranceFlowVisible();

  const hasDate = useMemo(() => {
    return item.state !== TrackTracerItemStateEnum.Create && !!item.date && dayjs(item.date).isValid();
  }, [item.state, item.date]);

  const getIcon = useMemo(() => {
    let icon = null;
    switch (item.state) {
      case TrackTracerItemStateEnum.Success:
        icon = <BiCheck color="#57bc98" />;
        break;
      case TrackTracerItemStateEnum.Pending:
        icon = <BiLoader color="var(--color-petrol)" />;
        break;
      case TrackTracerItemStateEnum.Create:
        icon = <BiPlus color="#d76f2c" />;
        break;
      case TrackTracerItemStateEnum.Error:
        icon = <BiXCircle color="#d76f2c" />;
        break;
    }

    return icon ? (
      <StateWrapper $hasDate={hasDate} $state={item.state}>
        {icon}
      </StateWrapper>
    ) : (
      icon
    );
  }, [item.state]);

  const signupForSalaryInsurance = () => {
    setSalaryInsuranceVisible(true);
  };

  const signupForUnemploymentInsurance = () => {};

  const signupForUnion = () => {
    setUnionFlowVisible(true);
  };

  const getButton = useMemo(() => {
    if (!item.action.name || item.state !== TrackTracerItemStateEnum.Create || !item.action.buttonText) return null;

    switch (item.action.name) {
      case TrackTracerItemActionsEnum.EnrollChildren: {
        if (!item.action.url) return null;
        return <Button href={item.action.url}>{item.action.buttonText}</Button>;
      }
      case TrackTracerItemActionsEnum.InviteCompanion: {
        if (!item.action.url) return null;
        return <Button href={item.action.url}>{item.action.buttonText}</Button>;
      }
      case TrackTracerItemActionsEnum.BuySalaryEnsurance:
        return (
          <Button onClick={signupForSalaryInsurance} loading={loading}>
            {item.action.buttonText}
          </Button>
        );
      case TrackTracerItemActionsEnum.BuyUnion:
        return (
          <Button onClick={signupForUnion} loading={loading}>
            {item.action.buttonText}
          </Button>
        );
      case TrackTracerItemActionsEnum.BuyUnemploymentInsurance:
        return (
          <Button onClick={signupForUnemploymentInsurance} loading={loading}>
            {item.action.buttonText}
          </Button>
        );
      default:
        return null;
    }
  }, [item.state, item.action, loading]);

  const getBadge = useMemo(() => {
    if (!item.state) return null;
    switch (item.state) {
      case TrackTracerItemStateEnum.Success:
        return <Badge $state={item.state}>{item.badgeText}</Badge>;
      case TrackTracerItemStateEnum.Pending:
        return <Badge $state={item.state}>{item.badgeText}</Badge>;
      case TrackTracerItemStateEnum.Error:
        return <Badge $state={item.state}>{item.badgeText}</Badge>;
    }
  }, [item.state, item.badgeText]);

  const getIllustration = useMemo(() => {
    if (!item.action.name || item.state !== TrackTracerItemStateEnum.Create) return null;

    switch (item.action.name) {
      case TrackTracerItemActionsEnum.BuySalaryEnsurance:
        return (
          <AnimationWrapper>
            <BiCoin size={50} color="var(--color-orange)" />
          </AnimationWrapper>
        );
      case TrackTracerItemActionsEnum.BuyUnion:
        return (
          <AnimationWrapper>
            <BiDonateHeart size={50} color="var(--color-orange)" />
          </AnimationWrapper>
        );
      case TrackTracerItemActionsEnum.EnrollChildren:
        return (
          <AnimationWrapper>
            <BiCoin size={50} color="var(--color-orange)" />
          </AnimationWrapper>
        );
      case TrackTracerItemActionsEnum.InviteCompanion:
        return (
          <AnimationWrapper>
            <BiUserPlus size={50} color="var(--color-orange)" />
          </AnimationWrapper>
        );
      case TrackTracerItemActionsEnum.BuyUnemploymentInsurance:
        return (
          <AnimationWrapper>
            <BiHeart size={50} color="var(--color-orange)" />
          </AnimationWrapper>
        );
    }
  }, [item.state, item.action]);

  return (
    <Wrapper>
      <Inner canHover={item.action.name !== TrackTracerItemActionsEnum.None && item.state === TrackTracerItemStateEnum.Create}>
        {hasDate && <Date>{dayjs(item.date).format("DD. MMM YYYY kl HH:mm")}</Date>}
        {getIcon}
        {getBadge}
        {getIllustration}
        {item.title && <Title>{item.title}</Title>}
        {item.description && <Description dangerouslySetInnerHTML={{ __html: item.description }} />}
        {getButton && <ButtonWrapper>{getButton}</ButtonWrapper>}
      </Inner>
    </Wrapper>
  );
}
