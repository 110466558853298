import StepContainer from "../../../../components/StepContainer";
import { useStore } from "../../../../store/store";
import StepRoutes from "../../../../constants/Routes";
import { AseFieldset } from "../../../../utils/global.styles";
import { InnerRow, Row, Spacer, StepQuestion } from "./FitWithYouStep.styles";
import Checkbox from "../../../../components/Checkbox";
import Select from "../../../../components/Select";
import GiftCardPopup from "../../../../components/GiftCardPopup";

export default function FitWithYouStep() {
  const t = useStore.useTranslations();
  const setPayload = useStore.useSetPayload();
  const payload = useStore.usePayload();
  const setRouteStack = useStore.useSetRouteStack();
  const unimploymentInsuranceOptions = useStore.useUnimploymentInsuranceOptions();

  return (
    <StepContainer
      stepTitle={t?.fitWithYouStep.title}
      progress={0.5}
      buttonText={t?.fitWithYouStep.buttonLabel}
      helpText={t?.fitWithYouStep.helpText}
      onNext={() => {
        if ((!payload.haveYouEveryHadDanishUnemploymentInsurance || (payload.haveYouEveryHadDanishUnemploymentInsurance && payload.currentUnemploymentBenefits)) && payload.areYouPrimarilyWorkingInDenmark && !payload.haveYouRecentlyWorkedAbroad) {
          setRouteStack(StepRoutes.ConsentStep, payload);
        } else {
          const cause = [];
          if (payload.haveYouEveryHadDanishUnemploymentInsurance) {
            cause.push("Har været medlem af a-kasse");
          }

          if (!payload.areYouPrimarilyWorkingInDenmark) {
            cause.push("Arbejder ikke primært i danmark");
          }

          if (payload.haveYouRecentlyWorkedAbroad) {
            cause.push("Har arbejdet i udlandet fornyligt");
          }

          setPayload({
            cause: cause.join(", "),
          });
          setRouteStack(StepRoutes.NeedToContactYouStep, payload);
        }
      }}
      nextDisabled={payload.haveYouEveryHadDanishUnemploymentInsurance && !payload.currentUnemploymentBenefits}
    >
      <AseFieldset>
        <Row>
          <StepQuestion>{t?.fitWithYouStep.priorHadUnemploymentInsurance}</StepQuestion>
          <Spacer />
          <InnerRow>
            <Checkbox
              onChange={() => {
                setPayload({ haveYouEveryHadDanishUnemploymentInsurance: true, currentlyHasUnemploymentBenefits: false });
              }}
              text={t?.unemploymentInsuranceStep.answerYes}
              checked={payload.haveYouEveryHadDanishUnemploymentInsurance}
            />
            <Checkbox
              onChange={() => {
                setPayload({
                  haveYouEveryHadDanishUnemploymentInsurance: false,
                  currentUnemploymentBenefits: undefined,
                  currentlyHasUnemploymentBenefits: false,
                });
              }}
              text={t?.unemploymentInsuranceStep.answerNo}
              checked={!payload.haveYouEveryHadDanishUnemploymentInsurance}
            />
          </InnerRow>
        </Row>

        {payload.haveYouEveryHadDanishUnemploymentInsurance && (
          <div style={{ paddingBottom: "1.4rem" }}>
            <Select
              id="member_akasse_which"
              options={unimploymentInsuranceOptions}
              label={t?.general.which}
              defaultOption={t?.fitWithYouStep.chooseUnemploymentInsurance}
              selectedOption={unimploymentInsuranceOptions.find((o) => o.id === payload.currentUnemploymentBenefits)}
              onSelect={(id) => {
                setPayload({ currentUnemploymentBenefits: id });
              }}
            />
          </div>
        )}

        <Row>
          <StepQuestion>{t?.fitWithYouStep.worksInDenmark}</StepQuestion>
          <Spacer />
          <InnerRow>
            <Checkbox
              onChange={() => {
                setPayload({ areYouPrimarilyWorkingInDenmark: true });
              }}
              text={t?.unemploymentInsuranceStep.answerYes}
              checked={payload.areYouPrimarilyWorkingInDenmark}
            />
            <Checkbox
              onChange={() => {
                setPayload({
                  areYouPrimarilyWorkingInDenmark: false,
                });
              }}
              text={t?.unemploymentInsuranceStep.answerNo}
              checked={!payload.areYouPrimarilyWorkingInDenmark}
            />
          </InnerRow>
        </Row>

        <Row>
          <StepQuestion>Har fornyligt arbejdet i udlandet{/*t?.fitWithYouStep.neverWorkedAbroad*/}</StepQuestion>
          <Spacer />
          <InnerRow>
            <Checkbox
              onChange={() => {
                setPayload({ haveYouRecentlyWorkedAbroad: true });
              }}
              text={t?.unemploymentInsuranceStep.answerYes}
              checked={payload.haveYouRecentlyWorkedAbroad}
            />
            <Checkbox
              onChange={() => {
                setPayload({
                  haveYouRecentlyWorkedAbroad: false,
                });
              }}
              text={t?.unemploymentInsuranceStep.answerNo}
              checked={!payload.haveYouRecentlyWorkedAbroad}
            />
          </InnerRow>
        </Row>
      </AseFieldset>
    </StepContainer>
  );
}
