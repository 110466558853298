import React, { useState } from "react";
import { useMemo } from "react";
import RadioButton from "../../../../components/RadioButton";
import StepContainer from "../../../../components/StepContainer";
import TextInput from "../../../../components/TextInput";
import TotalAmountBox from "../../../../components/TotalAmountBox";
import StepRoutes from "../../../../constants/Routes";
import { useStore } from "../../../../store/store";
import { PaymentTypeEnum } from "../../../../types/PaymentTypesEnum";
import { PaymentFrequencyTypeEnum } from "../../../../types/PaymentFrequencyTypeEnum";
import { postEnrollment } from "../../../../utils/api";
import Analytics from "../../../../utils/analytics";
import { formatNumber } from "../../../../utils/formatNumber";
import Modal from "../../../../components/Modal";
import { ASESummaryHeader, AseFieldset, AseFormInputRow, AseList } from "../../../../utils/global.styles";
import { TrackingFlowTypeEnum } from "../../../../types/TrackingFlowTypeEnum";

interface FormData {
  [key: string]: { value: string | boolean; error: boolean };
}

export default function PaymentStep() {
  const t = useStore.useTranslations();
  const setPayload = useStore.useSetPayload();
  const payload = useStore.usePayload();
  const setRouteStack = useStore.useSetRouteStack();
  const offerEnabled = useStore.useOfferEnabled();
  const routeStack = useStore.useRouteStack();

  const initialFormData = {
    accountNo: { value: "", error: true },
    regNo: { value: "", error: true },
  } as FormData;

  const [formData, setFormData] = useState(initialFormData);
  const [formDirty, setFormDirty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mobilepayLink, setMobilepayLink] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);

  const setValue = (field: string, value: string | boolean, error: boolean) => {
    setFormData({
      ...formData,
      [field]: {
        ...formData[field],
        value,
        error,
      },
    });
  };

  const rows = useMemo(() => {
    const r = [];

    const UnemploymentInsurancePriceBase = payload.wantsUnemploymentBenefits ? Number(t?.productsStep.unemploymentInsurance.price) : 0;
    const UnemploymentInsurancePrice = payload.paymentFrequency === PaymentFrequencyTypeEnum.Monthly ? UnemploymentInsurancePriceBase : UnemploymentInsurancePriceBase * 3;

    const FamilyUnionPrice = 0;

    const FeePrice = payload.paymentType === PaymentTypeEnum.MOBILE_PAY ? Number(payload.paymentFrequency === PaymentFrequencyTypeEnum.Monthly ? t?.paymentStep.mobilePay.monthlyServiceFee : t?.paymentStep.mobilePay.quarterlyServiceFee) ?? 0 : Number(payload.paymentFrequency === PaymentFrequencyTypeEnum.Monthly ? t?.paymentStep.bs.monthlyServiceFee : t?.paymentStep.bs.quarterlyServiceFee) ?? 0;

    let totalAmount = UnemploymentInsurancePrice;

    if (payload.wantsFamilyUnion) {
      totalAmount = totalAmount + FamilyUnionPrice;
    }

    if (payload.wantsUnemploymentBenefits) {
      totalAmount = totalAmount + FeePrice;
    }

    const suffix = payload.paymentFrequency === PaymentFrequencyTypeEnum.Monthly ? t?.general.krPerMonth : t?.general.krPerQuarterly;

    if (payload.wantsUnemploymentBenefits) {
      r.push({
        label: t?.productsStep.unemploymentInsurance.title,
        value: `${formatNumber(UnemploymentInsurancePrice)} ${suffix}`,
      });
    }

    if (payload.wantsFamilyUnion) {
      r.push({
        label: `${t?.productsStep.familyUnion.title} (tilføjes som partner)`,
        value: `0 ${t?.general.krPerMonth}`,
      });
    }

    if (payload.wantsUnemploymentBenefits) {
      r.push({
        label: t?.general.fee,
        value: `${formatNumber(FeePrice)} ${suffix}`,
      });
    }

    r.push({
      label: t?.general.totalAmount,
      value: `${formatNumber(totalAmount)} ${suffix}`,
      bold: true,
    });

    return r as { label: string; value: string; bold?: boolean }[];
  }, [payload, t]);

  const invalid = useMemo(() => {
    if (payload.paymentType === PaymentTypeEnum.MOBILE_PAY) {
      return false;
    } else if (payload.paymentType === PaymentTypeEnum.BSPAYMENT) {
      return Object.values(formData)
        .map((v) => v.error)
        .includes(true);
    } else {
      return true;
    }
  }, [payload, formData]);

  const hideMobilePay = useMemo(() => {
    return payload.wantsUnemploymentBenefits && (payload.wantsUnion || payload.wantsFamilyUnion);
  }, [payload]);

  const totalPrice = useMemo(() => {
    const UnemploymentInsurancePrice = payload.wantsUnemploymentBenefits ? Number(t?.productsStep.unemploymentInsurance.price) : 0;
    const UnionPrice = payload.wantsUnion && payload.occupation !== "SelfEmployed" ? Number(t?.productsStep.union.price) : 0;

    return UnemploymentInsurancePrice + (offerEnabled && payload.wantsUnemploymentBenefits ? 0 : UnionPrice);
  }, [payload, t]);

  return (
    <>
      <StepContainer
        stepTitle={t?.paymentStep.title}
        progress={0.95}
        loading={loading}
        subtitle="Velkommen til familiefagforeningen!"
        buttonText={t?.paymentStep.buttonLabel}
        helpText={t?.paymentStep.helpText}
        withSuccessButton
        onNext={async () => {
          if (loading) return;

          if (
            (payload.paymentType === PaymentTypeEnum.MOBILE_PAY && Boolean(payload.paymentFrequency)) ||
            (payload.paymentType === PaymentTypeEnum.BSPAYMENT &&
              Boolean(payload.paymentFrequency) &&
              !Object.values(formData)
                .map((v) => v.error)
                .includes(true))
          ) {
            try {
              const accountDetails = {
                regNo: formData.regNo.value as string,
                accountNo: formData.accountNo.value as string,
              };

              setPayload(accountDetails);
              setLoading(true);

              await postEnrollment({
                ...payload,
                ...accountDetails,
              });

              // Add tracking
              Analytics.getInstance().trackPurchase(payload, offerEnabled, totalPrice, TrackingFlowTypeEnum.PARTNER);
              Analytics.getInstance().trackStepCount(routeStack.length, TrackingFlowTypeEnum.PARTNER);
              Analytics.getInstance().trackAllConsent("collected", payload.consents, TrackingFlowTypeEnum.PARTNER);

              setRouteStack(StepRoutes.CompletedStep, payload);
            } catch (err) {
              setShowErrorModal(true);
            } finally {
              setLoading(false);
            }
          }
        }}
        nextDisabled={invalid || loading}
        previousDisabled={loading}
      >
        <AseFieldset>
          <div className="ASESummaryHeader">
            <h3>
              <strong>{t?.paymentStep.frequencyTitle}</strong>
            </h3>
          </div>
          <AseList>
            <RadioButton
              onChange={() =>
                setPayload({
                  paymentFrequency: PaymentFrequencyTypeEnum.Monthly,
                })
              }
              disabled={loading}
              id={PaymentFrequencyTypeEnum.Monthly}
              checked={payload.paymentFrequency === PaymentFrequencyTypeEnum.Monthly}
            >
              {t?.paymentStep.monthly}
            </RadioButton>
            <RadioButton
              onChange={() =>
                setPayload({
                  paymentFrequency: PaymentFrequencyTypeEnum.Quarterly,
                })
              }
              disabled={loading}
              id={PaymentFrequencyTypeEnum.Quarterly}
              checked={payload.paymentFrequency === PaymentFrequencyTypeEnum.Quarterly}
            >
              {t?.paymentStep.quaterly}
            </RadioButton>
          </AseList>
        </AseFieldset>

        <AseFieldset style={{ paddingBottom: "1.4rem" }}>
          <ASESummaryHeader>
            <h3>
              <strong>{t?.paymentStep.typeTitle}</strong>
            </h3>
          </ASESummaryHeader>
          <AseList>
            {!hideMobilePay && (
              <RadioButton onChange={() => setPayload({ paymentType: PaymentTypeEnum.MOBILE_PAY })} disabled={loading} id={PaymentTypeEnum.MOBILE_PAY} checked={payload.paymentType === PaymentTypeEnum.MOBILE_PAY} prefix={<img src="/assets/images/mobilepay.png" style={{ width: 30, height: 30, marginRight: 10 }} alt="mobilepay" />}>
                MobilePay
              </RadioButton>
            )}
            <RadioButton onChange={() => setPayload({ paymentType: PaymentTypeEnum.BSPAYMENT })} disabled={loading} id={PaymentTypeEnum.BSPAYMENT} checked={payload.paymentType === PaymentTypeEnum.BSPAYMENT} prefix={<img src="/assets/images/betalingsservice.png" style={{ width: 30, height: 30, marginRight: 10 }} alt="betalingsservice" />}>
              Betalingsservice
            </RadioButton>
          </AseList>
        </AseFieldset>

        {payload.paymentType === PaymentTypeEnum.BSPAYMENT && (
          <div style={{ paddingBottom: "1.4rem" }}>
            <p>{t?.paymentStep.description}</p>

            <AseFormInputRow>
              <TextInput
                label={t?.informationAboutYouStep.regNo}
                value={formData.regNo.value as string}
                onChange={(e) => {
                  const regex = new RegExp("^[0-9]{4}$");
                  const value = e.currentTarget.value.slice(0, 4);
                  setValue("regNo", value, !regex.test(value));
                  setFormDirty(true);
                }}
                disabled={loading}
                inputMode="numeric"
                type="number"
                placeholder={t?.informationAboutYouStep.regNo}
                pattern="[0-9]{4}"
                id="regNo"
                hasError={Boolean(formDirty && formData.regNo.error)}
                error={Boolean(formDirty && formData.regNo.error) ? t?.informationAboutYouStep.errorRegNo : ""}
              />

              <TextInput
                label={t?.informationAboutYouStep.accountNo}
                value={formData.accountNo.value as string}
                onChange={(e) => {
                  const regex = new RegExp("^[0-9]{6,10}$");
                  const value = e.currentTarget.value.slice(0, 10);
                  setValue("accountNo", value, !regex.test(value));
                  setFormDirty(true);
                }}
                disabled={loading}
                inputMode="numeric"
                type="number"
                pattern="[0-9]{6,10}"
                placeholder={t?.informationAboutYouStep.accountNo}
                onBlur={() => {
                  // Add upto 10 digits with zeros in front

                  if ((formData.accountNo.value as string).length < 10 && (formData.accountNo.value as string).length >= 6) {
                    const missingNumberOfZeros = 10 - (formData.accountNo.value as string).length;

                    let newAccountNo = formData.accountNo.value;
                    Array.from(Array(missingNumberOfZeros).keys()).forEach(() => {
                      newAccountNo = `0${newAccountNo}`;
                    });

                    setValue("accountNo", newAccountNo, false);
                  }
                }}
                id="accountNo"
                hasError={Boolean(formDirty && formData.accountNo.error)}
                error={Boolean(formDirty && formData.accountNo.error) ? t?.informationAboutYouStep.errorAccountNo : ""}
              />
            </AseFormInputRow>
          </div>
        )}

        <TotalAmountBox rows={rows} hideHeader />

        {mobilepayLink && (
          <div>
            <p>Hvis MobilePays side, hvor du skal taste dit telefonnummer, ikke åbner, så klik på knappen herunder.</p>
            <a className="button" href={mobilepayLink} target="_blank" rel="noreferrer">
              Åbn MobilePays side
            </a>{" "}
          </div>
        )}
      </StepContainer>
      <Modal modalDescriptionHtml={t?.paymentStep.errorMessage ?? ""} open={showErrorModal} onClose={() => setShowErrorModal(false)} />
    </>
  );
}
