import { useCallback, useEffect, useRef, useState } from "react";
import StepContainer from "../../../../components/StepContainer";
import { useStore } from "../../../../store/store";
import TextInput from "../../../../components/TextInput";
import StepRoutes from "../../../../constants/Routes";
import CPRLink from "../../../../components/CPRLink";
import { EmailValidationResponse } from "../../../../types/EmailValidationResponse";
import { postEmailValidation } from "../../../../utils/api";
import Spinner from "../../../../components/Spinner";
import debounce from "lodash.debounce";
import Modal from "../../../../components/Modal";
import { AseFieldset, AseList } from "../../../../utils/global.styles";
import AddressInput from "../../../../components/AddressInput";
import { AddressData } from "../../../../components/AddressInput/AddressInput";

interface FormData {
  [key: string]: { value: string | boolean; error: boolean };
}

export default function InformationAboutYouStep() {
  const t = useStore.useTranslations();
  const setPayload = useStore.useSetPayload();
  const payload = useStore.usePayload();
  const setRouteStack = useStore.useSetRouteStack();

  const initialFormData = {
    socialSecurityNumber: {
      value: payload.socialSecurityNumber,
      error: !Boolean(payload.socialSecurityNumber),
    },
    firstName: { value: payload.firstName, error: !Boolean(payload.firstName) },
    lastName: { value: payload.lastName, error: !Boolean(payload.lastName) },
    address: {
      value: payload.address ?? "",
      error: !Boolean(payload.address),
    },
    addressText: {
      value: payload.addressText ?? "",
      error: !Boolean(payload.addressText),
    },
    zipCode: {
      value: payload.zipCode ?? "",
      error: !Boolean(payload.zipCode),
    },
    city: {
      value: payload.city ?? "",
      error: !Boolean(payload.city),
    },
    floor: {
      value: payload.floor ?? "",
      error: !Boolean(payload.floor),
    },
    door: {
      value: payload.door ?? "",
      error: !Boolean(payload.door),
    },
    houseNumber: {
      value: payload.houseNumber ?? "",
      error: !Boolean(payload.houseNumber),
    },
    phone: { value: payload.mobilePhone, error: !Boolean(payload.mobilePhone) },
    email: { value: payload.mailAddress, error: !Boolean(payload.mailAddress) },
  } as FormData;

  const [formData, setFormData] = useState(initialFormData);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [isValidatingEmail, setIsValidatingEmail] = useState(false);
  const [emailValidationResponse, setEmailValidationResponse] = useState<EmailValidationResponse>({
    email: "",
    score: 0,
    suggestion: "",
    verdict: "",
  });

  const setValue = (field: string, value: string | boolean, error: boolean) => {
    setFormData({
      ...formData,
      [field]: {
        ...formData[field],
        value,
        error,
      },
    });
  };

  const cprHasError = (cpr: string) => {
    if (!cpr) {
      return true;
    }

    const inputWithRemovedDash = cpr.replace("-", "");
    if (inputWithRemovedDash.length !== 10) {
      return true;
    }

    if (!/^[0-9]+$/.test(inputWithRemovedDash)) {
      return true;
    }

    const day = parseInt(inputWithRemovedDash.substring(0, 2));

    if (day < 0 || day > 31) {
      return true;
    }

    const month = parseInt(inputWithRemovedDash.substring(2, 4));

    if (month < 0 || month > 12) {
      return true;
    }

    let year = parseInt(inputWithRemovedDash.substring(4, 6));

    if (year < 0 || year > 99) {
      return true;
    }

    return false;
  };

  const validateEmail = async (email: string) => {
    if (!email) return;
    try {
      setIsValidatingEmail(true);
      const response = await postEmailValidation(email);
      setEmailValidationResponse(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setIsValidatingEmail(false);
    }
  };

  const debouncedEmailValidation = useRef(
    debounce(async (email: string) => {
      await validateEmail(email);
    }, 300)
  ).current;

  const handleEmailChange = async (email: string) => {
    debouncedEmailValidation(email);
  };

  useEffect(() => {
    setValue("email", formData.email.value, !emailValidationResponse.verdict || emailValidationResponse.verdict === "Invalid");
  }, [emailValidationResponse]);

  // Trigger email validation in case the email payload is already set
  useEffect(() => {
    handleEmailChange(payload.mailAddress);
  }, [payload.mailAddress]);

  const onAddressSelected = useCallback(
    (addressData: AddressData) => {
      setFormData({
        ...formData,
        addressText: {
          value: addressData.addressText,
          error: false,
        },
        address: {
          value: addressData.address,
          error: !Boolean(addressData.address),
        },
        city: {
          value: addressData.city,
          error: !Boolean(addressData.city),
        },
        door: {
          value: addressData.door,
          error: false,
        },
        floor: {
          value: addressData.floor,
          error: false,
        },
        houseNumber: {
          value: addressData.houseNumber,
          error: !Boolean(addressData.houseNumber),
        },
        zipCode: {
          value: addressData.zipCode,
          error: !Boolean(addressData.zipCode),
        },
      });
    },
    [formData]
  );

  return (
    <>
      <StepContainer
        stepTitle={t?.informationAboutYouStep.title}
        subtitle="Velkommen til familiefagforeningen!"
        progress={0.85}
        buttonText={t?.informationAboutYouStep.buttonLabel}
        helpText={t?.informationAboutYouStep.helpText}
        onNext={async () => {
          const personalInfo = {
            firstName: formData.firstName.value,
            lastName: formData.lastName.value,
            socialSecurityNumber: formData.socialSecurityNumber.value,
            address: formData.address.value,
            addressText: formData.addressText.value,
            city: formData.city.value,
            zipCode: formData.zipCode.value,
            houseNumber: formData.houseNumber.value ?? "",
            floor: formData.floor.value ?? "",
            door: formData.door.value ?? "",
            mobilePhone: formData.phone.value,
            mailAddress: formData.email.value,
          };
          setPayload(personalInfo);
          setRouteStack(StepRoutes.SummaryStep, payload);
        }}
        nextDisabled={
          Object.values(formData)
            .map((v) => v.error)
            .includes(true) || isValidatingEmail
        }
      >
        <AseFieldset isRelative>
          <CPRLink />
          <AseList>
            <TextInput
              label={t?.informationAboutYouStep.cpr}
              value={formData.socialSecurityNumber.value as string}
              onChange={(e) => {
                const reg = /^\d{0,10}?$/;
                if (reg.test(e.currentTarget.value)) {
                  setValue("socialSecurityNumber", e.currentTarget.value, cprHasError(e.currentTarget.value));
                }
              }}
              inputMode="numeric"
              autoComplete="on"
              placeholder={t?.informationAboutYouStep.cpr}
              id="socialSecurityNumber"
              hasError={Boolean(formData.socialSecurityNumber.value) && cprHasError(formData.socialSecurityNumber.value as string)}
              error={Boolean(formSubmitted && formData.socialSecurityNumber.error) ? t?.informationAboutYouStep.errorCpr : ""}
            />
            <TextInput
              label={t?.informationAboutYouStep.firstName}
              value={formData.firstName.value as string}
              onChange={(e) => {
                setValue("firstName", e.currentTarget.value, e.currentTarget.validity.patternMismatch || !e.currentTarget.value);
              }}
              type="text"
              autoComplete="given-name"
              placeholder={t?.informationAboutYouStep.firstName}
              pattern=".{1,32}"
              id="firstName"
              error={Boolean(formSubmitted && formData.firstName.error) ? t?.informationAboutYouStep.errorFirstName : ""}
            />

            <TextInput
              label={t?.informationAboutYouStep.lastName}
              value={formData.lastName.value as string}
              onChange={(e) => {
                setValue("lastName", e.currentTarget.value, e.currentTarget.validity.patternMismatch || !e.currentTarget.value);
              }}
              type="text"
              autoComplete="family-name"
              placeholder={t?.informationAboutYouStep.lastName}
              pattern=".{1,32}"
              id="lastName"
              error={Boolean(formSubmitted && formData.lastName.error) ? t?.informationAboutYouStep.errorMissingLastName : ""}
            />

            <AddressInput label={t?.informationAboutYouStep.address} placeholder={t?.informationAboutYouStep.address} initValue={payload.addressText} onAddressSelected={onAddressSelected} />

            <TextInput
              label={t?.informationAboutYouStep.phone}
              type="text"
              value={formData.phone.value as string}
              onChange={(e) => {
                setValue("phone", e.currentTarget.value, e.currentTarget.validity.patternMismatch || !e.currentTarget.value);
              }}
              placeholder={t?.informationAboutYouStep.phone}
              pattern=".{4,32}"
              id="phone"
              autoComplete="phone"
              error={Boolean(formSubmitted && formData.phone.error) ? t?.informationAboutYouStep.errorPhone : ""}
            />

            <TextInput
              label={t?.informationAboutYouStep.mail}
              type="email"
              value={formData.email.value as string}
              onChange={(e) => {
                setValue("email", e.currentTarget.value, true);
                handleEmailChange(e.currentTarget.value);
              }}
              hasError={emailValidationResponse.verdict === "Invalid"}
              disableNativeError
              autoComplete="email"
              placeholder={t?.informationAboutYouStep.mail}
              id="email"
              error={emailValidationResponse.verdict === "Invalid" ? t?.informationAboutYouStep.errorEmail : ""}
              postfix={<Spinner show={isValidatingEmail} />}
            />

            {emailValidationResponse.suggestion && <p style={{ color: "var(--color-red)", fontSize: "0.8rem" }}>Mente du: {emailValidationResponse.suggestion}</p>}
          </AseList>
        </AseFieldset>
      </StepContainer>
      <Modal modalDescriptionHtml={t?.informationAboutYouStep.errorMessage ?? ""} open={showErrorModal} onClose={() => setShowErrorModal(false)} />
    </>
  );
}
