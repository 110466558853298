import { TrackTraceItemModel } from "./TrackTraceItemModel";

export enum TrackTracePaymentType {
  MobilePay = "MobilePay",
  PaymentService = "PaymentService",
}

export interface TrackTraceResponseModel {
  events: TrackTraceItemModel[];
  name: string;
  email: string;
  welcomeText: string;
  paymentType: TrackTracePaymentType;
}
